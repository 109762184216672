import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import Button from '../components/Button'
import Input from '../components/Input'

const Container = styled.section`
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 150px 0;
  /* align-items: center; */
  background: #f9f9f9;
`

const Login = styled.section`
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  max-width: 900px;
  /* display: flex;
  flex-direction: column; */

  padding: 50px;
  border-radius: 4px;
  background: #ffff;
  border: 1px solid #fefefe;

  @media (min-width: 900px) {
    min-width: 600px;
  }
  @media (max-width: 600px) {
    min-width: 300;
  }
  h5 {
    font-weight: 500;
    font-style: italic;
    text-transform: none;
  }

  a {
    color: #4099ff;
    text-decoration: underline;
  }

  input {
  }
  button {
    margin-top: 20px;
  }
`
const TopArea = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    cursor: pointer;
  }
`

export default ({ data }) => (
  <Layout header="dark">
    <Helmet>
      <title>Members - QLD Sports Aviators</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Container>
      {typeof window === 'object' &&
      localStorage.getItem('isLoggedIn') === null ? (
        <div>{navigate('/login/')}</div>
      ) : (
        <Login>
          <TopArea>
            <h5>
              SAFA Number:{' '}
              {typeof window === 'object' && localStorage.getItem('isLoggedIn')}
            </h5>
            <span
              onClick={(e) => {
                e.preventDefault()
                localStorage.removeItem('isLoggedIn')
                navigate('/login/')
              }}
            >
              Logout
            </span>
          </TopArea>
          <h3>QSA Constitution</h3>
          <ul>
            <li>
              <a
                target="_blank"
                href="/members/Qld SRA Constitution - V122021.pdf"
              >
                Qld SRA Constitution 2021
              </a>
            </li>
          </ul>
          <h3>Annual Reports</h3>

          <h4>2021 Reports</h4>
          <ul>
            <li>
              <a
                target="_blank"
                href="/members/QSA 2021 AGM Minutes - Signed.pdf"
              >
                QSA 2021 AGM Minutes - Signed.pdf
              </a>
            </li>

            <li>
              <a
                target="_blank"
                href="/members/QSA 2021 Committee Statement - Signed.pdf"
              >
                QSA 2021 Committee Statement - Signed
              </a>
            </li>
            <li>
              <a target="_blank" href="/members/QSA Annual Report 2020-21.pdf">
                QSA Annual Report 2020-21
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="/members/QSA AGM 2021 Financials Presented - Signed.pdf"
              >
                QSA AGM 2021 Financials Presented - Signed
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="/members/QSA 2021 Committee Summary - Signed.pdf"
              >
                QSA 2021 Committee Summary - Signed
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="/members/QSA Audited Financial Statements 2021.pdf"
              >
                QSA Audited Financial Statements 2021
              </a>
            </li>
          </ul>
          <h4>2020 Reports</h4>
          <ul>
            <li>
              <a target="_blank" href="/members/QSA 2020 Annual Report.pdf">
                QSA 2020 Annual Report
              </a>
            </li>
            <li>
              <a target="_blank" href="/members/QSA RDL Arrangements.pdf">
                QSA RDL Arrangements
              </a>
            </li>
          </ul>

          <h4>2019 Reports</h4>
          <ul>
            <li>
              <a target="_blank" href="/members/QSA 2019 Annual Report.pdf">
                QSA 2019 Annual Report
              </a>
            </li>
          </ul>
          <h4>2018 Reports</h4>
          <ul>
            <li>
              <a target="_blank" href="/members/QHGA - Annual Report 2018.pdf">
                QHGA 2018 Annual Report
              </a>
            </li>
            <li>
              <a target="_blank" href="/members/2018 QHGA Fin Audit Report.pdf">
                QHGA 2018 Fin Audit Report
              </a>
            </li>
          </ul>
          <h4>2014 - 2017 Reports</h4>
          <ul>
            <li>
              <a
                target="_blank"
                href="/members/QHGA 2017 Financial Audit Report.pdf"
              >
                QHGA 2017 Financial Audit Report
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="/members/Qld State Association Report for 2017 HGFA AGM.pdf"
              >
                Qld State Association Report for 2017 HGFA AGM
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="/members/Qld SRA Financial Statements 2016.pdf"
              >
                Qld SRA Financial Statements 2016
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="/members/2014-15-16 Financial Reports QSHGA.pdf"
              >
                QSHGA 2014-15-16 Financial Reports
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="/members/Qld SRA - Auditors Report 2014 15 16.pdf"
              >
                Qld SRA - Auditors Report 2014-15-16
              </a>
            </li>
          </ul>
        </Login>
      )}
    </Container>
  </Layout>
)

// export const query = graphql`
//   query LoginQuery { }
// `
